import React from 'react';
import tw from 'twin.macro';

export const FacebookIcon = ({ ...rest }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.9951 14.0216C28.0828 21.4146 21.9913 28.0673 13.8704 28.0006C6.12874 27.9374 -0.131323 21.5866 0.00209369 13.7515C0.128489 6.10586 6.41664 -0.0695832 14.1408 0.000592216C21.9843 0.0742764 28.0969 6.55849 27.9951 14.0216ZM15.0396 18.0392C15.0396 16.6778 15.0396 15.3164 15.0396 13.9585C15.0396 13.7304 15.0396 13.7269 15.2748 13.7269C15.9279 13.7269 16.5844 13.7199 17.2375 13.7304C17.4095 13.7339 17.4622 13.6638 17.4727 13.5094C17.4867 13.3234 17.5218 13.1409 17.5429 12.955C17.6131 12.3234 17.6869 11.6953 17.7501 11.0638C17.7711 10.8392 17.7606 10.8357 17.5394 10.8357C16.774 10.8357 16.0086 10.8322 15.2432 10.8392C15.1063 10.8392 15.0607 10.8006 15.0607 10.6602C15.0677 10.1059 15.0642 9.55147 15.0642 8.99708C15.0642 8.50586 15.4047 8.16901 15.8998 8.16901C16.4615 8.16901 17.0233 8.16551 17.5815 8.17252C17.729 8.17252 17.7746 8.11989 17.7711 7.97954C17.7676 7.10936 17.7676 6.23919 17.7711 5.36901C17.7711 5.20761 17.7149 5.15849 17.557 5.15849C16.8091 5.1655 16.0613 5.1655 15.3134 5.15849C14.7973 5.15498 14.2917 5.20059 13.7897 5.3234C12.3712 5.66375 11.5602 6.762 11.5111 8.1234C11.483 8.96901 11.5005 9.81814 11.504 10.6638C11.504 10.8181 11.4549 10.8673 11.3039 10.8638C10.8475 10.8567 10.3911 10.8638 9.93814 10.8567C9.7977 10.8532 9.75557 10.9059 9.75557 11.0392C9.75908 11.8743 9.76259 12.7094 9.75557 13.5445C9.75557 13.6953 9.81526 13.7304 9.95219 13.7269C10.377 13.7199 10.8054 13.7234 11.2302 13.7234C11.5005 13.7234 11.5005 13.7234 11.5005 13.9866C11.5005 16.6918 11.5005 19.3936 11.5005 22.0988C11.5005 22.3269 11.5005 22.3304 11.7323 22.3304C12.7504 22.3304 13.7651 22.3304 14.7833 22.3304C15.0361 22.3304 15.0361 22.3304 15.0361 22.0848C15.0396 20.7374 15.0396 19.3901 15.0396 18.0392Z"
      fill="currentColor"
      {...rest}
    />
  </svg>
);

export const InstagramIcon = ({ ...rest }) => (
  <svg width="28" height="28" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128 64C128 99.3462 99.3462 128 64 128C28.6538 128 0 99.3462 0 64C0 28.6538 28.6538 0 64 0C99.3462 0 128 28.6538 128 64ZM105.5 82.334C105.5 95.1095 95.1094 105.5 82.333 105.5H45.667C32.8916 105.5 22.5 95.1095 22.5 82.333V45.667C22.5 32.8905 32.8916 22.5 45.667 22.5H82.333C95.1094 22.5 105.5 32.8906 105.5 45.667V82.334ZM45.667 32.167C38.2244 32.167 32.167 38.2244 32.167 45.667V82.334C32.167 89.7766 38.2244 95.834 45.667 95.834H82.333C89.7756 95.834 95.833 89.7766 95.833 82.334V45.667C95.833 38.2244 89.7756 32.167 82.333 32.167H45.667ZM42.5 64C42.5 75.8554 52.1435 85.5 64 85.5C75.8565 85.5 85.5 75.8554 85.5 64C85.5 52.1425 75.8564 42.5 64 42.5C52.1436 42.5 42.5 52.1425 42.5 64ZM52.167 64C52.167 57.4764 57.4764 52.167 64 52.167C70.5245 52.167 75.833 57.4764 75.833 64C75.833 70.5245 70.5236 75.833 64 75.833C57.4764 75.833 52.167 70.5245 52.167 64ZM91.333 42.75C91.333 46.1096 88.6096 48.833 85.25 48.833C81.8904 48.833 79.167 46.1096 79.167 42.75C79.167 39.3905 81.8904 36.667 85.25 36.667C88.6096 36.667 91.333 39.3905 91.333 42.75Z"
      fill="currentColor"
      {...rest}
    />
  </svg>
);

export const LinkedInIcon = ({ ...rest }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0C6.26773 0 0 6.26773 0 14C0 21.7323 6.26773 28 14 28C21.7323 28 28 21.7323 28 14C28 6.26773 21.7323 0 14 0ZM9.80496 20.4663C8.76241 20.4663 7.7695 20.4663 6.73936 20.4663C6.73936 17.3759 6.73936 14.3227 6.73936 11.2199C7.74468 11.2199 8.76241 11.2199 9.80496 11.2199C9.80496 14.2979 9.80496 17.3635 9.80496 20.4663ZM8.25355 10.1525C7.42199 10.1401 6.72695 9.44504 6.70213 8.60106C6.6773 7.75709 7.39716 7.02482 8.26596 7.02482C9.14716 7.02482 9.82979 7.70745 9.82979 8.58865C9.8422 9.44503 9.10993 10.1649 8.25355 10.1525ZM18.2695 20.4663C18.2571 20.2926 18.2323 20.1064 18.2323 19.9326C18.2323 18.5922 18.2323 17.2394 18.2323 15.8989C18.2323 15.5887 18.2074 15.2784 18.1454 14.9805C17.984 14.0745 17.5621 13.6897 16.7057 13.6773C15.7748 13.6525 15.1543 14.0869 15.0053 14.9681C14.906 15.5514 14.8936 16.1596 14.8812 16.7553C14.8688 17.984 14.8812 19.2252 14.8812 20.4911C13.8635 20.4911 12.9202 20.4911 11.9397 20.4911C11.9397 17.4007 11.9397 14.3351 11.9397 11.2199C12.8582 11.2199 13.7766 11.2199 14.7447 11.2199C14.7447 11.6418 14.7447 12.0762 14.7447 12.6472C15.8121 11.3316 17.1028 10.9965 18.5798 11.2695C19.9947 11.5301 20.9131 12.2624 21.0372 13.6649C21.2358 15.9113 21.2234 18.1826 21.2979 20.4787C20.2057 20.4663 19.2624 20.4663 18.2695 20.4663Z"
      fill="currentColor"
      {...rest}
    />
  </svg>
);

export const TwitterIcon = ({ ...rest }) => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9982 6.00248e-06C6.38645 -0.00701593 0.0245768 6.14771 0 14.0088C0.0351097 21.768 6.27059 28.0175 14.0368 28C21.8874 27.9824 28.0245 21.5995 28.0105 13.9877C27.9964 6.25304 21.7434 0.00702794 13.9982 6.00248e-06ZM21.9997 10.6101C21.6978 10.9612 21.3677 11.2843 20.9956 11.5581C20.8903 11.6354 20.8481 11.7161 20.8516 11.8495C20.8832 14.0649 20.1845 16.0311 18.7837 17.7374C17.5057 19.2928 15.8661 20.2723 13.8964 20.6726C11.7863 21.1044 9.74995 20.8657 7.8119 19.8967C7.59071 19.7843 7.36952 19.672 7.12024 19.4964C8.83359 19.6439 10.3433 19.2401 11.7196 18.2254C11.5089 18.1938 11.3334 18.1763 11.1614 18.1341C10.08 17.8849 9.31459 17.2494 8.87572 16.2277C8.85466 16.1821 8.80902 16.1224 8.84412 16.0802C8.87923 16.0346 8.94243 16.0697 8.99159 16.0767C9.3813 16.1294 9.764 16.1153 10.1783 15.9995C8.63698 15.5395 7.80839 14.5424 7.68199 12.9239C8.14544 13.1416 8.57378 13.296 9.06532 13.2995C9.01616 13.2118 8.93892 13.1767 8.87923 13.1275C7.73817 12.2252 7.40112 10.5996 8.08224 9.3146C8.14544 9.19523 8.17353 9.19172 8.26482 9.29354C9.75698 11.0104 11.6353 12.0145 13.8824 12.3375C14.079 12.3656 14.2791 12.3656 14.4757 12.3972C14.6302 12.4253 14.6478 12.3727 14.6197 12.2357C14.4336 11.2702 14.6934 10.4206 15.3254 9.67974C16.484 8.33153 18.6046 8.23322 19.8826 9.46206C19.9809 9.55686 20.0617 9.57441 20.1916 9.54281C20.8165 9.39535 21.4029 9.16714 22.0067 8.82658C21.7539 9.55686 21.3256 10.087 20.7182 10.4802C20.7428 10.4943 20.7639 10.5048 20.7884 10.5189C21.3326 10.4381 21.8593 10.2906 22.4 10.0659C22.2771 10.2906 22.1366 10.4522 21.9997 10.6101Z"
      fill="currentColor"
      {...rest}
    />
  </svg>
);

export const ArrowIcon = ({ accent, arrowDirection, size, ...rest }) => (
  <svg
    width={size || '20'}
    height={size ? size / 2 : '10'}
    fill="none"
    viewBox="0 0 20 10"
    xmlns="http://www.w3.org/2000/svg"
    tw="inline-block"
    css={arrowDirection}
    {...rest}
  >
    <path
      d="M1 5h18M16 1l3.5 4L16 9"
      stroke={accent || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AccordionArrow = ({ className, ...rest }) => (
  <svg
    className={className ? className : undefined}
    width="16"
    height="9"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1 6.25L7 1L13 6.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareIcon = () => (
  <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 3l-10 5 10 5.5" stroke="currentColor" />
    <circle cx="12.5" cy="3" r="2.5" fill="currentColor" />
    <circle cx="12.5" cy="13" r="2.5" fill="currentColor" />
    <circle cx="2.5" cy="8" r="2.5" fill="currentColor" />
  </svg>
);
