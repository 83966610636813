import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import tw from 'twin.macro';
import { cn } from '../utils';
import '../styles/menu.css';
import SocialMediaIcons from './SocialMediaIcons';

const menuQuery = graphql`
  {
    datoCmsMenu {
      links {
        label
        href
      }
    }
  }
`;

const Menu = ({ isOpen }) => {
  const {
    datoCmsMenu: { links },
  } = useStaticQuery(menuQuery);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.nav
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={cn('menu', isOpen && 'is-open')}
        >
          <ul>
            {links?.map((link) => (
              <li key={link.label}>
                <a href={link.href}>{link.label}</a>
              </li>
            ))}
          </ul>
          <SocialMediaIcons tw="mt-6 opacity-0" className="social-media-icons" />
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

export default Menu;
