import React from 'react';
import tw, { css } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { A } from './index';
import Logo from './Logo';
import SocialMediaIcons from './SocialMediaIcons';

const gradientStyles = css`
  background: linear-gradient(180deg, #0a1236 4.97%, rgba(138, 237, 213, 0.57) 100%);
`;

const sectionStyles = tw`relative text-white w-full flex flex-col justify-between items-start py-12 px-5 md:(px-8 py-24) lg:px-20`;
const Inner = tw.div`flex flex-col lg:flex-row w-full mx-auto 2xl:max-w-6xl 3xl:max-w-7xl`;

const Footer = () => {
  const {
    datoCmsFooter: { companyName, address, email, links },
    allDatoCmsArticle,
  } = useStaticQuery(footerQuery);

  const latestArticles = allDatoCmsArticle.edges.map((a) => a.node);

  return (
    <footer css={[sectionStyles, gradientStyles]}>
      <Inner>
        <div tw="mb-16 flex flex-col lg:(w-3/5 mb-0 flex-row)">
          <a href="https://digitalhubdenmark.dk/" tw="inline-block mb-16 sm:(w-1/3) lg:mb-0">
            <Logo tw="w-40" />
          </a>
          <div tw="w-full lg:(block w-2/3 pr-28)">
            <p tw="font-semibold mb-3">Our Latest Articles</p>
            <ul>
              {latestArticles.map(({ slug, title }) => (
                <li key={slug} tw="border-l-2 border-red mb-3 pl-2" className="group">
                  <a href={`/${slug}`} tw="inline-block group-hover:underline">
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div tw="flex flex-col w-full lg:(flex-row w-2/5)">
          <div tw="w-1/2">
            <p tw="font-semibold mb-3">Links</p>
            <ul>
              {links.map(({ href, label }) => (
                <li key={href}>
                  <A href={href} tw="inline-block mb-2 text-base mb-2 sm:(text-base mb-2)">
                    {label}
                  </A>
                </li>
              ))}
            </ul>
          </div>
          <div tw="flex flex-col justify-between sm:w-1/2">
            <div tw="my-10 lg:my-0">
              <a href="https://www.google.com/maps/dir//digitalhubdenmark/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4652534697ca7e41:0x70305169134af7d2?sa=X&ved=2ahUKEwidttWw8rH3AhUhSvEDHY64BYAQ9Rd6BAhPEAU" title="Directions in Google Maps" target="_blank" rel="noreferrer">
                <span tw="font-semibold mb-3">{companyName}</span>
                <address tw="mt-3 not-italic text-base">{address}</address>
              </a>

              <a href={`mailto: ${email}`} tw="font-semibold mt-3 block">
                {email}
              </a>
            </div>
            <SocialMediaIcons />
          </div>
        </div>
      </Inner>
    </footer>
  );
};

const footerQuery = graphql`
  {
    datoCmsFooter {
      companyName
      address
      email
      links {
        label
        href
      }
    }
    allDatoCmsArticle(limit: 6, sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;

export default Footer;
