import React, { useState } from 'react';
import { Link } from 'gatsby';
import tw, { css, styled } from 'twin.macro';
import Logo from './Logo';
import Menu from './Menu';
import TiltButton from './TiltButton';
import { innerStyles } from './index';
import { cn } from '../utils';

const headerStyles = css`
  padding-top: 2.15rem;
  ${tw`absolute z-20 w-full sm:pt-6 text-white transition-colors`}
`;

const headerInnerStyles = tw`px-5 md:px-10 lg:px-20 2xl:px-0 2xl:max-w-7xl sm:pt-10 md:pt-12`;

const textDarkStyles = tw`text-blue-dark`;

const buttonStyles = css`
  width: 45px;
  height: 40px;
  ${tw`relative z-30 cursor-pointer ml-10`}
`;

const logoContainerStyles = tw`relative z-30 block w-40 sm:w-44 md:w-52 transition-colors duration-300`;

const Towards = styled.div`
  ${tw`towards text-center absolute pt-1 top-1/2 md:text-2xl`}
  font-size: 1.4rem;
  line-height: 1;
`;

const Buttons = tw.div`flex items-center relative z-30`;
const removeSVG = css`
  svg {
    display: none;
  }
`;
const blueBorderStyles = css`
  button {
    ${tw`border-blue-dark`}
  }
`;

const Header = ({ darkStyles }) => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToNewsletter = () => {
    if (typeof window !== 'undefined') {
      var el = document.getElementById('newsletter');
      el.scrollIntoView();
    }
  };

  return (
    <header
      css={[headerStyles, darkStyles && !isOpen && textDarkStyles]}
      className={cn(isOpen && 'menu-is-open')}
    >
      <div css={[innerStyles, headerInnerStyles, tw`flex justify-between items-center`]}>
        <Link css={logoContainerStyles} to="/" onClick={() => setIsOpen(false)}>
          <Towards>Doing Digital Differently</Towards>
        </Link>
        <Buttons>
          <TiltButton
            label="Sign up for News"
            onClick={scrollToNewsletter}
            className="tilt-button"
            tw="hidden md:block bg-white-10"
            css={[removeSVG, darkStyles && !isOpen && blueBorderStyles]}
          />
          <button
            css={buttonStyles}
            className={cn('button-toggle-menu', isOpen && 'is-open')}
            onClick={() => setIsOpen((value) => !value)}
            type="button"
          >
            <div className="lines">
              <span />
              <span />
              <span />
            </div>
          </button>
        </Buttons>
        <Menu isOpen={isOpen} />
      </div>
    </header>
  );
};

export default Header;
