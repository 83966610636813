import React from 'react';
import Header from './Header';
import Footer from './Footer';
import NewsletterSlim from './NewsletterSlim';
import StickyCTA from './StickyCTA';

const Layout = ({ children, articleType }) => {
  const isTalentPortrait = articleType === 'Tech Talent';

  return (
    <>
      {isTalentPortrait && (
        <StickyCTA
          text="Get to know all the tech talents"
          callToAction={{ label: 'Meet them here', href: 'https://talentedindenmark.dk/' }}
        />
      )}
      <Header darkStyles={articleType} />
      {children}
      <NewsletterSlim signUpFrom={isTalentPortrait ? 'talent' : null} />
      <Footer />
    </>
  );
};

export default Layout;
