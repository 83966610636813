import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import tw, { css } from 'twin.macro';
import { A } from './index';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from './Icons';

const soMeStyles = css`
  &:last-of-type {
    ${tw`mr-0`}
  }
  ${tw`relative block mr-4 z-10 rounded-full transition-colors duration-300`}
  &::after {
    content: '';
    ${tw`absolute w-5 h-5 top-1 left-1 z-minus-1 transition-colors duration-300`}
  }
  &:hover::after {
    ${tw`bg-white`}
  }
`;

const SocialMediaIcons = ({ ...rest }) => {
  const {
    datoCmsFooter: { facebookUrl, instagramUrl, linkedinUrl, twitterUrl },
  } = useStaticQuery(soMeQuery);
  return (
    <div tw="mb-2 flex" {...rest}>
      {facebookUrl && (
        <A href={facebookUrl} css={soMeStyles} tw="hover:text-facebook">
          <FacebookIcon />
        </A>
      )}
      {instagramUrl && (
        <A href={instagramUrl} css={soMeStyles} tw="hover:text-instagram">
          <InstagramIcon />
        </A>
      )}
      {linkedinUrl && (
        <A href={linkedinUrl} css={soMeStyles} tw="hover:text-linkedin">
          <LinkedInIcon />
        </A>
      )}
      {twitterUrl && (
        <A href={twitterUrl} css={soMeStyles} tw="hover:text-twitter">
          <TwitterIcon />
        </A>
      )}
    </div>
  );
};

export default SocialMediaIcons;

const soMeQuery = graphql`
  {
    datoCmsFooter {
      facebookUrl
      instagramUrl
      linkedinUrl
      twitterUrl
    }
  }
`;
