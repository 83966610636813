// import React from 'react';
import tw, { css, styled } from 'twin.macro';

export const Article = tw.article`w-full mx-auto p-5 lg:px-20 2xl:max-w-8xl `;

export const Container = tw.div`mx-auto w-full flex flex-col justify-between items-start`;

// export const headerInnerStyles = tw`mx-auto px-5 md:px-10 lg:px-20 2xl:px-0 2xl:max-w-6xl 3xl:max-w-7xl sm:pt-10 md:pt-12`;
export const innerStyles = tw`w-full mx-auto 2xl:max-w-6xl 3xl:max-w-7xl`;

export const Inner = styled.div(({ mt }) => [
  tw`lg:max-w-4xl w-full mx-auto`,
  mt && tw`mt-32 md:mt-40 lg:mt-32 3xl:mt-52`,
]);

export const Title = tw.h1`text-3xl font-semibold mt-5 mb-4`;

export const A = ({ children, href, ...rest }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
    {children}
  </a>
);

export const contentStyles = css`
  ${tw`lg:max-w-4xl mx-auto mt-10 sm:(mt-16)`}
  h2, h3 {
    ${tw`font-semibold mb-4`}
  }
  h4 {
    font-size: 1.5rem;
    font-style: italic;
    text-decoration: underline;
    margin-bottom: 1.5em;
    display: inline-block;

    a {
      padding: 0.25rem;
    }
  }
  ul,
  ol,
  p {
    font-size: 1.125rem;
    line-height: 1.625;
    margin-bottom: 2em;
  }
  ul,
  ol {
    ${tw`pl-5`}

    li {
      ${tw`mt-4 pl-2`}
    }
  }
  ul {
    list-style: disc;

    ul {
      list-style: circle;
    }
  }
  ol {
    list-style: decimal;
    
    ol {
      list-style: lower-alpha;
    }
  }
  li {
    list-style: inherit;
  }
  iframe {
    width: 100%;
  }
  a {
    position: relative;
    font-weight: 600;
    transition: color 150ms linear;
    ${tw`text-red underline hover:text-blue-dark`}
  }
  figcaption {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;
