import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import TiltButton from './TiltButton';

const stickyStyles = css`
  ${tw`py-6 block sm:sticky top-0 px-5 md:px-8 lg:px-20 text-white`}
  z-index: 99;
  background-color: #ffe964;
  background-color: #0a1236;
  // background-image: linear-gradient(0deg, #ffe964 -15%, #0a1236 100%);
`;

const closeButton = tw`
  mr-8 underline hidden sm:inline-block
`;

const Inner = tw.div`flex flex-col sm:flex-row w-full mx-auto 2xl:max-w-6xl 3xl:max-w-7xl`;

const StickyCTA = ({ text, callToAction, alternativeCallToActionLabel }) => {
  const [showSticky, setShowSticky] = useState(true);
  if (!showSticky) return null;
  return (
    <div css={stickyStyles}>
      <Inner tw="flex justify-between flex-col sm:(flex-row items-center)">
        <p tw="w-full mb-6 font-bold text-lg sm:(text-xl mb-0 w-1/2) lg:w-2/3">{text}</p>
        <div>
          <button onClick={() => setShowSticky(false)} css={closeButton}>
            Close
          </button>
          <TiltButton
            label={alternativeCallToActionLabel || callToAction.label}
            to={callToAction.href}
            external
            accent="#ffe964"
          />
        </div>
      </Inner>
    </div>
  );
};

export default StickyCTA;
